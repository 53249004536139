<template>
    <div class="elem-step-box">
        <div class="item-box" v-for="(item, idx) in steps" :key="'progress-' + idx">
            <div class="icon-box">
                <div class="line-top" :class="{ highlight: current >= idx }"></div>
                <div class="sign error liefeng-icon liefeng-icon-close" v-if="item.status === 'error'"></div>
                <div class="sign success liefeng-icon liefeng-icon-check" v-else-if="current > idx"></div>
                <div class="number" :class="{ current: current === idx }" v-else>{{ idx + 1 }}</div>
                <div class="line-bottom" :class="{ highlight: current - 1 >= idx }"></div>
            </div>
            <div class="text-content">
                <div class="title">{{ item.title }}</div>
                <div class="desc" v-html="item.desc"></div>
                <slot name="content" :item="item" :idx="idx"></slot>
            </div>
        </div>

        <div class="empty-box" v-if="steps && steps.length <= 0">当前记录为空</div>
    </div>
</template>

<script>
export default {
    props: {
        current: {
            type: Number,
            default: 0,
        },
        steps: Array,
        title: {
            type: String,
            default: "",
        },
    },
}
</script>

<style lang="less">
.elem-step-box {
    .item-box {
        position: relative;
        padding: 20px 0;
        display: flex;

        > .icon-box {
            width: 30px;
            flex-shrink: 0;

            .line-top {
                position: absolute;
                top: -1px;
                left: 13px;
                width: 4px;
                height: 12px;
                background: #bebebe;
                border-radius: 0 0 2px 2px;
            }

            .line-bottom {
                position: absolute;
                bottom: -1px;
                left: 13px;
                width: 4px;
                top: 60px;
                background: #bebebe;
                border-radius: 2px 2px 0 0;
            }

            .highlight {
                background: #2faaf7;
            }

            .number {
                width: 30px;
                height: 30px;
                background: #e5e5e5;
                color: #999;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                &.current {
                    background: #2db7f5;
                    color: #fff;
                }
            }

            .sign {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 18px;
                font-weight: bold;
                line-height: 1;
            }

            .success {
                background: #2db7f5;
                color: #fff;
            }

            .error {
                background: #e23232;
                color: #fff;
            }
        }

        &:first-child {
            .icon-box .line-top {
                display: none;
            }
        }

        &:last-child {
            .icon-box .line-bottom {
                display: none;
            }
        }

        .text-content {
            padding-left: 15px;
            flex: 1;

            .title {
                color: #333;
                font-size: 16px;
                line-height: 30px;
            }

            .desc {
                color: #999;
                font-size: 12px;
                line-height: 26px;
            }
        }

        .inspect-box {
            padding: 5px 7px;
            background: #f9f9f9;
            line-height: 1;
            color: #666;
            border: 1px solid #e3e3e3;
            margin-left: 15px;
            font-size: 12px;
            border-radius: 5px;
            flex-shrink: 0;
        }
    }

    .empty-box {
        width: 100%;
        margin: 20px 0;
        text-align: center;
        color: #888;
    }
}
</style>
